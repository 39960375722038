import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetDto, TagDto } from 'src/app/api/asset';
import { VulnerabilityService } from 'src/app/layout/service/vulnerability.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { Product, Suite } from 'src/app/api/product';
import { Message, MessageService, TreeNode } from 'primeng/api';

@Component({
  selector: 'app-vuln-details',
  templateUrl: './vuln-details.component.html',
  styleUrls: ['./vuln-details.component.scss']

})
export class VulnDetailsComponent implements OnInit {
  vulnId: string = '';
  toolId: string = '';
  vuln?: any = undefined;
  isLoading = true;
  product?: Product = undefined;
  suite?: Suite = undefined;
  baseVulns: string[] = [];
  isBaseLoading = true;
  sonarHierarchy: TreeNode[] = [];
  modifiedSynopsis: string = '';

  baseVulnerabilities: any[] = [];

  wasMessages: Message[] = [];

  constructor(private route: ActivatedRoute,
    private vulnerabilityService: VulnerabilityService, private MessageService: MessageService, private productService: ProductService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.vulnId = params['vulnId'];

      //Get the tool id from the query string
      this.toolId = this.route.snapshot.queryParams['toolId'];

      this.vulnerabilityService.getVulnerability(this.vulnId, this.toolId).subscribe((data: any) => {
        this.vuln = data;

        if (this.vuln == null) {
          this.isLoading = false;
          return;
        }

        if (this.toolId == "Snyk") {
          this.modifiedSynopsis = (this.vuln?.synopsis ?? "??") + " issue in " + (this.vuln?.package ?? "??") + " (v." + (this.vuln?.version ?? "??") + ")";
        } else {
          this.modifiedSynopsis = this.vuln?.synopsis ?? "??";
        }
        

        if (this.toolId == "Tenable WAS") {

          if (this.vuln.uris.length >= 25) {
            this.wasMessages.push({ severity: 'warn', summary: 'Warning', detail: 'Tenable WAS caps the number of URL instances for an individual vulnerability at 25. Be aware that the total number of instances may be higher.' });
          }
        }

        this.isLoading = false;

        if (this.vuln.sonarProjects != null && this.vuln.sonarProjects != '') {
          this.sonarHierarchy = this.transformSonarHierarchy(this.vuln);
        };

        if (this.vuln.baseVulnerabilities == null || this.vuln.baseVulnerabilities == '') {
          this.isBaseLoading = false;
        }
        else { 

          this.baseVulns = this.getBaseVulns(this.vuln.baseVulnerabilities);
          const urlEncodedBase = encodeURIComponent(this.vuln.baseVulnerabilities);
          this.vulnerabilityService.getBaseVulnHierarchy(urlEncodedBase).subscribe((data: any) => {
            if (data.length != 0) {
              this.baseVulnerabilities = this.transformBaseVulnerabilityData(data);
            } else {
              this.baseVulnerabilities = [];
            }
            console.log(this.baseVulnerabilities);
            this.isBaseLoading = false;
          });
        }

        if (this.vuln.lensProductTypeName == 'Product') {
          this.productService.getProductByIdTopbar(this.vuln.lensObjectId).subscribe((product: any) => {            
            this.product = product;            
          });
        } else if (this.vuln.lensProductTypeName == 'Suite') {

          this.productService.getSuiteById(this.vuln.lensObjectId).subscribe((suite: any) => {
            this.suite = suite;
          });
        }       
      });
    });
  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

  getAge(date: string): string {
    const diff = new Date().getTime() - new Date(date).getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days + ' days';
  }

  isAged(): string {

    const diff = new Date().getTime() - new Date(this.vuln.createdAt).getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days > 14) {
      return "aged";
    }
    return "new";
  }



  niceDate(date: string): string {
    //return date in format: dd MMMM YYYY, h:mm:ss
    return new Date(date).toLocaleString('en-GB', { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
   
  }

  getPenTestLink(): string {

    if (this.vuln?.practiceName == "External Pen Test") {
      if (this.vuln?.type == "External") {
        return `https://irissoftware.sharepoint.com/sites/EngineeringIntranet/Lists/External%20PTT/DispForm.aspx?ID=${this.vuln?.testId}`;
      } else {
        return `https://irissoftware.sharepoint.com/sites/EngineeringIntranet/Lists/1Day%20Test%20Details/DispForm.aspx?ID=${this.vuln?.testId}`;
      }
    } else {
      return `https://irissoftware.sharepoint.com/sites/EngineeringIntranet/Lists/OSFcalendar/DispForm.aspx?ID=${this.vuln?.testId}`;
    }

  }

  generateIssueHyperlink() : string {

    switch (this.vuln?.toolName) {
      case "Nessus":
        //return `https://cloud.tenable.com/tio/app.html#/assets-uw/all-assets/list/details/host/${this.vuln?.assetId}/findings`;
        return "";
      case "Snyk":
        return `https://app.snyk.io/org/${this.vuln?.slug}/reporting?v=1&context[page]=issues-detail&issue_status=%255B%2522Open%2522%255D&issue_by=Severity&table_issues_detail_cols=SEVERITY%257CSCORE%257CCVE%257CCWE%257CPROJECT%257CEXPLOIT%2520MATURITY%257CCOMPUTED%2520FIXABILITY%257CINTRODUCED%257CSNYK%2520PRODUCT`;
      case "Sonarqube (Enterprise)":
        //return `https://sonarqube.enterprise.central.ngiris.io/issues?projects=${this.vuln?.project}&resolved=false&statuses=OPEN&types=VULNERABILITY&open=${this.vuln?.id}`;
      case "Tenable WAS":
        return `https://cloud.tenable.com/webapp#/scans/my-scans/scan-details/${this.vuln?.scanId}/findings/history/:targetScanId`;
      case "SharePoint":
          return `https://irissoftware.sharepoint.com/sites/EngineeringIntranet/Lists/OSF_PTT/DispForm.aspx?ID=${this.vuln?.key}`;        
      case "Lens":
        if (this.vuln?.lensProductTypeName == "Product" && this.vuln?.type == "One Day Test") {
          return `https://lens.iris.co.uk/product/${this.vuln?.lensObjectId}/sdlc/onedaypentest?id=${this.vuln?.testId}`;
        }
        else {
          return `https://lens.iris.co.uk/product/${this.vuln?.lensObjectId}/sdlc/pentests?id=${this.vuln?.testId}`;
        }
        return '';
      default:
        return '';
    }     
  }

  getBaseVulns(bases: string): string[] {
    return bases.split(',').map(vuln => vuln.trim());
  }

  transformBaseVulnerabilityData(data: any[]): TreeNode[] {
    return data.map(item => this.convertBaseToTreeNode(item));
  }

  convertBaseToTreeNode(item: any): TreeNode {

    return {
      label: item.label,
      data: {
        id: item.id,
        type: item.type,
        url: item.url,
        isLast: item.isLast 
      },
      expanded: true, // Set the node to be expanded by default
      children: item.children ? item.children.map((child: any) => this.convertBaseToTreeNode(child)) : []
    };
  }

  transformSonarHierarchy(vuln: any): TreeNode[] {

    let projects: TreeNode[] = [];

    console.log(vuln);

    vuln.sonarProjects.forEach((project: any) => {

      let projectNode: TreeNode = {
        label: project.key,
        expanded: true,
        data: {
          type: "project",
          id: project.key,
        },
        children: [] // Ensure children is initialized as an empty array
      };

      project.components.forEach((component: any) => {       

        let componentNode: TreeNode = {
          label: component.key,
          expanded: true,
          data: {
            type: "component",
            id: component.key,
          },
          children: []
        };

        component.issues.forEach((issue: any) => {
          componentNode.children?.push({
            label: "Line " + (issue.startingLine == issue.endingLine ? issue.startingLine : issue.startingLine + " to " + issue.endingLine),
            expanded: true,
            data: {
              type: "issue",
              id: issue.key,
              url: "https://sonarqube.enterprise.central.ngiris.io/issues?projects=" + project.key + "&resolved=false&statuses=OPEN&types=VULNERABILITY&open=" + issue.key
            }
          });
        });

        projectNode.children?.push(componentNode); // Optional chaining in case children is undefined
      });

      projects.push(projectNode);
    });

    return projects;
  }



}
