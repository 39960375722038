<h1><img src="../assets/layout/images/bug.png" width="30" alt="Bug Icon" *ngIf="vuln?.vulnerabilityType == 'Vulnerability'" /><span style="margin-left:10px">{{vuln?.vulnerabilityType ?? "Vulnerability"}} Details</span></h1>
<p-toast></p-toast>

<div *ngIf="isLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>

<div *ngIf="!isLoading">

  <div>
    <p-tag [styleClass]="'cursor-pointer vulnerability-badge tool-' + tagify(vuln?.toolName)" pTooltip="Tool" tooltipPosition="top">
    </p-tag>
    <p-tag value="{{ vuln?.severity }} Severity" [styleClass]="'cursor-pointer vulnerability-badge severity-' + tagify(vuln?.severity)" pTooltip="Severity" tooltipPosition="top">
    </p-tag>
    <p-tag value="{{ isAged() }}" [styleClass]="'cursor-pointer vulnerability-badge age-' + tagify(isAged())" pTooltip="Age" tooltipPosition="top">
    </p-tag>
  </div>

  <div class="productdetails-container">
    <div class="productdetails-top-row">
      <div class="productdetails-left-column widerLeftColumn">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Core Details
            </span>
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Vulnerability ID:</div>
          <div class="productdetails-value">{{ vuln?.id ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">
            Affected {{ vuln?.lensProductTypeName }}:
          </div>
          <div class="productdetails-value">
            <a [routerLink]="['/' + (vuln?.lensProductTypeName?.toLowerCase()), vuln?.lensObjectId ?? 0, 'dashboard']" class="no-decoration">
              {{ vuln?.lensProductTypeName == 'Product' ? product?.Name : suite?.Name }}
            </a>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">SDLC Practice:</div>
          <div class="productdetails-value">{{ vuln?.practiceName ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">First Seen:</div>
          <div class="productdetails-value">{{ niceDate(vuln?.createdAt ?? "") }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Age:</div>
          <div class="productdetails-value">{{ getAge(vuln?.createdAt ?? "") }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Base Vulnerabilities:</div>
          <div class="productdetails-value" *ngIf="baseVulns.length">
            <p-tree class="w-full md:w-30rem" [value]="baseVulnerabilities" [loading]="isBaseLoading"  [style]="{border: 'none', padding: '0px'}">
              <ng-template let-node pTemplate="default">
                <div class="tree-node-content" [ngClass]="{'bold-label': node.data.isLast}" [style]="{padding: '0px'}">
                  <!-- Material Icon -->
                  <i class="pi pi-exclamation-circle" style="margin-right: 6px;"></i>
                  <!-- Label -->
                  <a *ngIf="node.data.url" [href]="node.data.url" target="_blank" rel="noopener noreferrer">
                    {{ node.label }}
                  </a>
                  <span *ngIf="!node.data.url">{{ node.label }}</span>
                </div>
              </ng-template>
              <ng-template pTemplate="loading">
                <span>Loading...</span>
              </ng-template>
            </p-tree>
            <div *ngIf="vuln.toolName == 'SharePoint' || vuln.toolName == 'Lens'">
              <i style="color:#4d4646">The CWE categorisation for this vulnerability was inferred by an AI; it may not be accurate. Speak to a member of Archicture and Security for details.</i>
            </div>
          </div>
        </div>

        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Instances:</div>
          <div class="productdetails-value">{{ vuln?.instances ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Synopsis:</div>
          <div class="productdetails-value">{{ modifiedSynopsis }}</div>
        </div>
      </p-panel>
      </div>
     
    <div class="productdetails-right-column">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <span class="font-bold details-panel-header-title">
              Product Vulnerability Link
            </span>
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div *ngIf="vuln.toolName == 'Nessus'">
            <div style="padding:10px">
              <img src="../assets/layout/images/badgelogos/nessus.png" width="90" alt="Nessus Logo" />
            </div>
            <span style="font-style:italic; margin-top:20px">Login to Nessus and search for one of the affected assets, or click on the Nessus Link next to the asset below.</span>
          </div>
          <div *ngIf="vuln.toolName == 'Sonarqube (Enterprise)'">
            <div style="padding:10px">
              <img src="../assets/layout/images/badgelogos/sonarqube.png" width="90" alt="Sonar Logo" />
            </div>
            <span style="font-style:italic; margin-top:20px">Click on one of the links on an affected line in the Component Locations list below.</span>
          </div>
          <div *ngIf="vuln.toolName != 'Nessus' && vuln.toolName != 'Sonarqube (Enterprise)'">
            <div style="padding:10px">
              <a href="{{ generateIssueHyperlink()  }}" target="_blank">Product Vulnerability Link</a>
              <a href="{{ generateIssueHyperlink()  }}" target="_blank" class="pi pi-external-link" title="Product Vulnerability Link" style="margin-left: 10px"></a>
            </div>
            <span style="font-style:italic; margin-top:20px">Note: You need suitable permissions to view vulnerability details in the parent tool.</span>
          </div>

        </div>
      </p-panel>
    </div>

    </div>
    <div class="productdetails-bottom-row" *ngIf="vuln?.toolName == 'Nessus'">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <img src="../assets/layout/images/badgelogos/nessus.png" width="90" alt="Nessus Logo" />
          </div>
        </ng-template>
        <h4>Assets</h4>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Asset Tag:</div>
          <div class="productdetails-value">{{ vuln?.assetTag ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Affected Assets:</div>
          <div class="productdetails-value">
            <p-table [value]="vuln.assets">
              <ng-template pTemplate="header">
                <tr>
                  <th>Hostname</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-asset>
                <tr>
                  <td>
                    <a [routerLink]="['/' +'asset', asset.assetID, 'details']" class="no-decoration">
                      {{ asset.hostname }}
                    </a>  <a [href]="'https://cloud.tenable.com/tio/app.html#/assets-uw/all-assets/list/details/host/' + asset.assetID + '/findings'" target="_blank" class="pi pi-external-link" title="Nessus Link" style="margin-left: 10px"></a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

        <h4>Vulnerability Details</h4>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Issue:</div>
          <div class="productdetails-value">
            <a [href]="'https://www.tenable.com/plugins/nessus/' + (vuln?.pluginId ?? '0')"
               target="_blank">
              {{ vuln?.name ?? "" }}
            </a>
            <a [href]="'https://www.tenable.com/plugins/nessus/' + (vuln?.pluginId ?? '0')" target="_blank" class="pi pi-external-link" title="Plugin Link" style="margin-left: 10px"></a>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">CVSS Base Score:</div>
          <div class="productdetails-value">{{ vuln?.cvssBaseScore ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Description:</div>
          <div class="productdetails-value">{{ vuln?.description ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Solution:</div>
          <div class="productdetails-value">{{ vuln?.solution ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">State:</div>
          <div class="productdetails-value">{{ vuln?.state ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">ID:</div>
          <div class="productdetails-value">
            <ul>
              <li *ngFor="let xref of vuln?.xRefs">
                {{ xref.id ?? "" }}
              </li>
            </ul>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="productdetails-bottom-row" *ngIf="vuln?.toolName == 'Snyk'">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <img src="../assets/layout/images/badgelogos/snyk.png" width="90" alt="Snyk Logo" />
          </div>
        </ng-template>
        <h4>Projects</h4>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Org ID:</div>
          <div class="productdetails-value">{{ vuln?.org ?? "" }} (<a href="https://app.snyk.io/org/{{ vuln?.slug }}">{{ vuln?.slug }}</a>)</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Projects:</div>
          <div class="productdetails-value">
            <p-table [value]="vuln.projects">
              <ng-template pTemplate="header">
                <tr>
                  <th>Project</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-project>
                <tr>
                  <td>
                    {{project.name }}
                    <a href="https://app.snyk.io/org/{{ vuln.slug }}/project/{{ project.id }}" target="_blank" class="pi pi-external-link" title="Project Link" style="margin-left: 10px"></a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <h4>Vulnerability Details</h4>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Type:</div>
          <div class="productdetails-value">{{ vuln?.vulnerabilityType ?? "" }}</div>
        </div>

        <div *ngIf="vuln.vulnerabilityType === 'Licence' && (vuln.severity === 'high' || vuln.severity === 'critical')" class="info-box high-severity">
          <div class="icon">
            <i class="pi pi-exclamation-circle"></i>
          </div>
          <div class="content">
            <h3>Licence Action Required</h3>
            <p>This package has a restrictive (copyleft) licence which cannot be used in proprietary, closed-source, commercial software. Continuing to use the licence in IRIS software puts the company at risk of legal action.</p>
            <p>An alternative should be found, and the licenced pacakge should be replaced or removed as soon as possible.</p>
          </div>
        </div>

        <div *ngIf="vuln.vulnerabilityType === 'Licence' && (vuln.severity === 'medium')" class="info-box medium-severity">
          <div class="icon">
            <i class="pi pi-question-circle"></i>
          </div>
          <div class="content">
            <h3>Unknown Licence</h3>
            <p>Snyk was unable to determine the nature of the licence for this package, therefore further investigation is required to understand if it is safe. It is possible that this package has a permissive licence, but it also may indicate that this may be a commercial package or a bespoke licence which requires further consideration.</p>
            <p>Investigate the licence and if deemed safe, add an Ignore record to Snyk. If deemed unsafe, remove the package at the next available opportunity. See <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/How-to-get-Snyk-off-your-back.aspx#licences">this guide</a> for details.</p>

          </div>
        </div>

        <div *ngIf="vuln.vulnerabilityType === 'Licence' && (vuln.severity === 'low')" class="info-box low-severity">
          <div class="icon">
            <i class="pi pi-question-circle"></i>
          </div>
          <div class="content">
            <h3>Low Licence Concern</h3>
            <p>Snyk has detected a weak copyleft licence in this package. Weak copyleft licences are usually safe to use in commercial software providing certain conditions are met.</p>
            <p>As long as we are not modifying or forking the source of the original package, low licence concerns can usually be ignored. See <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/How-to-get-Snyk-off-your-back.aspx#licences">this guide</a> for details.</p>

          </div>
        </div>


        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Key:</div>
          <div class="productdetails-value">{{ vuln?.key ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Package:</div>
          <div class="productdetails-value">{{ vuln?.package ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Version:</div>
          <div class="productdetails-value">{{ vuln?.version ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Priority Score: <a href="https://docs.snyk.io/manage-risk/prioritize-issues-for-fixing/priority-score" target="_blank"><i class="pi pi-question-circle" style="margin-right: 6px;"></i></a></div>
          <div class="productdetails-value">
            <div class="bottomMetrics" *ngIf="vuln?.score">
              <div class="metricBox" style="align-items:flex-start !important">
                <ngx-gauge [value]="vuln.score"
                           [max]="1000"
                           [type]="'full'"
                           [size]="120"
                           [ngClass]="'gaugeRed'"
                           [foregroundColor]="'#b02336'"
                           [backgroundColor]="'#d9d0d1'"
                           [cap]="'round'"
                           [thick]="5">
                </ngx-gauge>
              </div>
            </div>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Reachability:</div>
          <div class="productdetails-value">{{ vuln?.reachability ?? "" }}</div>
        </div>
        <h4>Fixability</h4>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Is Fixable Manually?:</div>
          <div class="productdetails-value">{{ vuln?.isFixableManually ?? "false" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Is Fixable by Snyk?:</div>
          <div class="productdetails-value">{{ vuln?.isFixableSnyk ?? "false" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Is Fixable Upstream?:</div>
          <div class="productdetails-value">{{ vuln?.isFixableUpstream ?? "false" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Is Patchable?:</div>
          <div class="productdetails-value">{{ vuln?.isPatchable ?? "false" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Is Upgradeable?:</div>
          <div class="productdetails-value">{{ vuln?.isUpgradeable ?? "false" }}</div>
        </div>

        <h4>Sources</h4>
        <ng-container *ngFor="let problem of vuln?.problems">
          <div class="productdetails-attribute">
            <div class="productdetails-label standardAttributeColumn">{{ problem?.source ?? "" }}</div>
            <div class="productdetails-value">
              <a href="{{ problem?.url }}" target="_blank">{{ problem?.id ?? "" }}</a>
              <a href="{{ problem?.url }}" target="_blank" class="pi pi-external-link" title="Source Link" style="margin-left: 10px"></a>
            </div>
          </div>
        </ng-container>

      </p-panel>
    </div>

    <div class="productdetails-bottom-row" *ngIf="vuln?.toolName == 'Sonarqube (Enterprise)'">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <img src="../assets/layout/images/badgelogos/sonarqube.png" width="90" alt="Sonar Logo" />
          </div>
        </ng-template>

        <h4>Components</h4>

        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Locations:</div>
          <div class="productdetails-value">

            <p-tree class="w-full md:w-30rem" [value]="sonarHierarchy" [style]="{border: 'none', padding: '0px'}">
              <ng-template let-node pTemplate="default">
                <div class="tree-node-content" [style]="{padding: '0px'}">
                  <!-- Material Icon -->
                  <i *ngIf="node.data.type == 'project'" class="pi pi-objects-column" style="margin-right: 6px;"></i>
                  <i *ngIf="node.data.type == 'component'" class="pi pi-cog" style="margin-right: 6px;"></i>
                  <i *ngIf="node.data.type == 'issue'" class="pi pi-align-justify" style="margin-right: 6px;"></i>
                  <!-- Label -->
                  <span>{{ node.label }}</span>
                  <a *ngIf="node.data.url" [href]="node.data.url" target="_blank" class="pi pi-external-link" title="Sonar Link" style="margin-left: 10px"></a>
                </div>
              </ng-template>
            </p-tree>
          </div>
        </div>

        <h4>Rule Details</h4>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Introduction:</div>
          <div class="productdetails-value" [innerHTML]="vuln?.ruleDetails?.introduction ?? ''"></div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Effort:</div>
          <div class="productdetails-value">{{ vuln?.effort ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Debt:</div>
          <div class="productdetails-value">{{ vuln?.debt ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Rule:</div>
          <div class="productdetails-value">{{ vuln?.rule ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Name:</div>
          <div class="productdetails-value">{{ vuln?.ruleDetails?.name ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Language:</div>
          <div class="productdetails-value">{{ vuln?.ruleDetails?.langName ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Remediation:</div>
          <div class="productdetails-value" [innerHTML]="vuln?.ruleDetails?.remediation ?? ''"></div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Root Cause:</div>
          <div class="productdetails-value" [innerHTML]="vuln?.ruleDetails?.rootCause ?? ''"></div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Resource:</div>
          <div class="productdetails-value" [innerHTML]="vuln?.ruleDetails?.resources ?? ''"></div>
        </div>

      </p-panel>
    </div>

    <div class="productdetails-bottom-row" *ngIf="vuln?.toolName == 'Tenable WAS'">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <img src="../assets/layout/images/badgelogos/tenable.webp" width="30" alt="Tenable Logo" />
          </div>
        </ng-template>
        <h4>Scan Details</h4>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Scan:</div>
          <div class="productdetails-value">{{ vuln?.scanName ?? "" }} ({{ vuln?.scanId}})</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Config ID:</div>
          <div class="productdetails-value">{{ vuln?.configId ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Target:</div>
          <div class="productdetails-value">
            <a [href]="vuln?.scanTarget" target="_blank">{{ vuln?.scanTarget ?? "" }}</a>
            <a [href]="vuln?.scanTarget" target="_blank" class="pi pi-external-link" title="Target Link" style="margin-left: 10px"></a>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Scan Type: <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/Weekly-Vulnerability-Report---User-Guide.aspx#notes-on-tenable-was" target="_blank"><i class="pi pi-question-circle" style="margin-right: 6px;"></i></a></div>
          <div class="productdetails-value">{{ vuln?.scanType ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Scanned at:</div>
          <div class="productdetails-value">{{ niceDate(vuln?.scanDate ?? "") }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Affected URIs:</div>
          <div class="productdetails-value">
            <p-messages [(value)]="wasMessages" [enableService]="false" [closable]="true"></p-messages>
            <p-table [value]="vuln.uris">
              <ng-template pTemplate="header">
                <tr>
                  <th>URI</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-uri>
                <tr>
                  <td>
                    <a href="{{uri}}" target="_blank">{{ uri }}</a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <br />
        <h4>Plugin Details</h4>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Plugin:</div>
          <div class="productdetails-value">
            <a [href]="'https://www.tenable.com/plugins/was/' + (vuln?.pluginId ?? '0')"
               target="_blank">
              {{ vuln?.name ?? '' }}
            </a>
            <a [href]="'https://www.tenable.com/plugins/was/' + (vuln?.pluginId ?? '0')" target="_blank" class="pi pi-external-link" title="Plugin Link" style="margin-left: 10px"></a>
          </div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">CVSS Base Score:</div>
          <div class="productdetails-value">{{ vuln?.cvssBaseScore ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Output:</div>
          <div class="productdetails-value">{{ vuln?.output ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Description:</div>
          <div class="productdetails-value">{{ vuln?.description ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Solution:</div>
          <div class="productdetails-value">{{ vuln?.solution ?? "" }}</div>
        </div>
      </p-panel>
    </div>

    <div class="productdetails-bottom-row" *ngIf="vuln?.toolName == 'Lens'">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <img src="../assets/layout/images/iris-logo.jpg" width="110" alt="IRIS Logo" />
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Type:</div>
          <div class="productdetails-value">{{ vuln?.type ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Title:</div>
          <div class="productdetails-value">{{ vuln?.title ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Remediation:</div>
          <div class="productdetails-value">{{ vuln?.remediation ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">CVSS:</div>
          <div class="productdetails-value">{{ vuln?.cvssBaseScore ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Complexity:</div>
          <div class="productdetails-value">{{ vuln?.complexity ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Category:</div>
          <div class="productdetails-value">{{ vuln?.category ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Progress:</div>
          <div class="productdetails-value">{{ vuln?.progress ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Priority:</div>
          <div class="productdetails-value">{{ vuln?.priority ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Fix Date:</div>
          <div class="productdetails-value">{{ vuln?.fixDate ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Vector:</div>
          <div class="productdetails-value">{{ vuln?.vector ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Next Action:</div>
          <div class="productdetails-value">{{ vuln?.nextAction ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Test ID:</div>
          <div class="productdetails-value">
            <a href="{{ getPenTestLink() }}" target="_blank">{{ vuln?.testId ?? "" }}</a>
            <a href="{{ getPenTestLink() }}" target="_blank" class="pi pi-external-link" title="Test Link" style="margin-left: 10px"></a>
          </div>
        </div>
      </p-panel>
    </div>


    <div class="productdetails-bottom-row" *ngIf="vuln?.toolName == 'SharePoint'">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="details-panel-header-container">
            <img src="../assets/layout/images/badgelogos/sharepoint.png" width="110" alt="SharePoint Logo" />
          </div>
        </ng-template>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Title:</div>
          <div class="productdetails-value">{{ vuln?.title ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Created By:</div>
          <div class="productdetails-value">{{ vuln?.createdBy ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Remediation:</div>
          <div class="productdetails-value">{{ vuln?.remediation ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Product ID:</div>
          <div class="productdetails-value">{{ vuln?.productId ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Progress:</div>
          <div class="productdetails-value">{{ vuln?.progress ?? "" }}</div>
        </div>
        <div class="productdetails-attribute">
          <div class="productdetails-label standardAttributeColumn">Test ID:</div>
          <div class="productdetails-value">
            <a href="{{ getPenTestLink() }}" target="_blank">{{ vuln?.testId ?? "" }}</a>
            <a href="{{ getPenTestLink() }}" target="_blank" class="pi pi-external-link" title="Test Link" style="margin-left: 10px"></a>
          </div>
        </div>
      </p-panel>
    </div>

  </div>
</div>
