import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Division, Product, ProductPutDto, ProductRequestDto, ProductType, Suite } from "src/app/api/product";
import { environment } from "src/environments/environment";

@Injectable(
    {
        providedIn: 'root'
    }
)

export class ToolExternalService {
  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient) { }

  getSnykVulnerabilityData(id: number, productType?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/snyk/Vulnerabilities?id=` + id + ((productType) ? "&productTypeName=" + productType : ""))
  }

  getSnykVulnerabilityTrendData(id: number, productType?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/snyk/Vulnerabilities/trends?id=` + id + ((productType) ? "&productTypeName=" + productType : ""))
  }

  getSnykLicenceTrendData(id: number, productType?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/snyk/LicenceConcerns/trends?id=` + id + ((productType) ? "&productTypeName=" + productType : ""))
  }

  getSnykLicenceData(id: number, productType?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/snyk/LicenceConcerns?id=` + id + ((productType) ? "&productTypeName=" + productType : ""))
  }

  getSonarIssues(id: number, productType?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/sonar/Issues?id=` + id + ((productType) ? "&productTypeName=" + productType : ""))
  }

  getSonarMetrics(id: number, productType?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/sonar/CodeMetrics?id=` + id + ((productType) ? "&productTypeName=" + productType : ""))
  }

  getSonarMetricsMeta(id: number, productType?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/sonar/CodeMetrics/meta?id=` + id + ((productType) ? "&productTypeName=" + productType : ""))
  }

  getSonarMetricsTrendData(component: string, measure: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/sonar/CodeMetrics/trends?component=` + component + `&measure=` + measure)
  }

  getWasIssues(id: number, productType?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/was/WasIssues?id=` + id + ((productType) ? "&productTypeName=" + productType : ""))
  }

  getAssets(id: number, productType?: string): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/nessus/assets/?objectId=` + id + ((productType) ? "&productTypeName=" + productType : ""))
  }

  getAsset( id: string ): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/nessus/assets/` + id)
  }

  getTechDebtReport (tiers: string, divisions: string, suites: string, products: string, timeframe: number): Observable<any> {
    return this.http.get(this.baseApiUrl + `/api/tools/sonar/codemetrics/techdebt/report?tiers=` + tiers + `&divisions=` + divisions + `&suites=` + suites + `&products=` + products + `&timeframe=` + timeframe)
  }

}
