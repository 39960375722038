import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ProductMetric } from "src/app/api/product-metric";
import { ProductMetricService } from '../../../service/product/product-metric.service';
import { ToolExternalService } from '../../../service/tool-external.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'techdebtreport',
  templateUrl: './techdebtreport.component.html',
  styleUrls: ['./techdebtreport.component.scss']
})

export class TechDebtReport implements OnInit {

  products: any[] = [];
  suites: any[] = [];
  divisions: any[] = [];
  tiers: any[] = [];

  selectedTiers: any[] = [];
  selectedDivisions: any[] = [];
  selectedSuites: any[] = [];
  selectedProducts: any[] = [];

  timeframe: number = 7;
  customDate: Date | null = new Date(new Date().setDate(new Date().getDate() - 7));
  minDate: Date | null = new Date(new Date().setDate(new Date().getDate() - 90));
  maxDate: Date | null = new Date(new Date().setDate(new Date().getDate() - 1));

  isLoading = true;
  isDataLoading = false;
  hasData = false;

  data: any = [];
  breakdown: any = [];

  chartData: any;
  chartOptions: any;

  baseChartOptons: any = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        type: 'time',
        time: {
          unit: 'day'
        }
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Days Effort'
        }
      }
    }
  };

  constructor(private route: ActivatedRoute,
    private productService: ProductService,
    private productMetricService: ProductMetricService,
    private toolExternalService: ToolExternalService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    forkJoin({
      suites: this.productService.getAllSuites(),
      divisions: this.productService.getAllDivisions(),
      tiers: this.productMetricService.getAllTiers(),
      products: this.productService.getAllProducts()
    }).subscribe(({ suites, divisions, tiers, products }) => {

      this.suites = suites.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      this.divisions = divisions.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      this.tiers = tiers;
      this.products = products.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));

      this.route.queryParams.subscribe(params => {

        this.selectedTiers = this.getSelectedItems(params['tiers'], this.tiers);
        this.selectedDivisions = this.getSelectedItems(params['divisions'], this.divisions);
        this.selectedSuites = this.getSelectedItems(params['suites'], this.suites);
        this.selectedProducts = this.getSelectedItems(params['products'], this.products);

        this.isLoading = false;
      });
    });
  }

  private getSelectedItems(paramValues: string, allItems: any[]): any[] {
    if (!paramValues) {
      return [];
    }
    const ids = paramValues.split(',').map(Id => Id.trim());
    return allItems.filter(item => ids.includes(item.Id.toString()));
  }

  onTiersChange(event: any) {
    this.selectedTiers = event.value;
  }

  onDivisionsChange(event: any) {
    this.selectedDivisions = event.value;
  }

  onSuitesChange(event: any) {
    this.selectedSuites = event.value;
  }

  onProductsChange(event: any) {
    this.selectedProducts = event.value;
  }

  onDateSelect(): void {
    if (this.customDate) {
      const today = new Date();
      const selectedDate = new Date(this.customDate);
      const timeDiff = today.getTime() - selectedDate.getTime();
      this.timeframe = Math.floor(timeDiff / (1000 * 3600 * 24));
    }
  }

  buildReport(): void {

    this.isDataLoading = true;

    const tiers = this.selectedTiers.map(item => item.Id).join(',');
    const divisions = this.selectedDivisions.map(item => item.Id).join(',');
    const suites = this.selectedSuites.map(item => item.Id).join(',');
    const products = this.selectedProducts.map(item => item.Id).join(',');


    this.toolExternalService.getTechDebtReport(tiers, divisions, suites, products, this.timeframe).subscribe((data: any) => {
      this.data = data;
      this.breakdown = data.breakdown;

      this.chartData = this.transformChartData(data.days);
      this.chartOptions = this.baseChartOptons;
      this.chartOptions.scales.y = {
        title: {
          display: true,
          text: 'Days Effort'
        },
        suggestedMax: (yData: any) => {
          const maxValue = Math.max(...this.chartData.datasets[0].data);
          return maxValue + (maxValue * 0.01);
        },
        suggestedMin: (yData: any) => {
          const minValue = Math.min(...this.chartData.datasets[0].data);
          if (minValue - (minValue * 0.01) < 0) {
            return 0;
          } else {
            return minValue - (minValue * 0.01);
          }
        }
      };


      this.isDataLoading = false;
      this.hasData = true;
    });
  }

  getDelta(current: number, previous: number): number {

    const delta = current - previous;
    return Math.abs(delta);
  }

  getDeltaClass(delta: number) : string {

    if (delta == 0) {
      return '';
    }

    if (delta > 0) {
      return 'text-increase';
    } else {
      return 'text-decrease';
    }
  }

  getDeltaSymbol(delta: number): string {

    if (delta == 0) {
      return '';
    }

    if (delta > 0) {
      return '▲';
    } else {
      return '▼';
    }
  }

  getItemPct(value: number): number {

    const total = this.data?.current ?? 0;
    if (total == 0) {
      return 0;
    }
    const pct = (value / total) * 100;
    return pct;
  }

  transformChartData(data: any): any {

    const labels: string[] = [];
    const values: number[] = [];

    Object.entries(data).forEach(([key, value]) => {
      labels.push(key);    // Push the date (key)
      values.push(value as number);  // Assert value is a number
    });

    // Create the chart data object
    const datasets: any[] = [];

    datasets.push({
      label: "Tech Debt",
      data: values,
      fill: false,
      borderColor: '#2c2cbf',
      tension: 0.1,
      borderWidth: 2,
      pointRadius: 0
    });

    const chartData: any = {
      labels: labels,
      datasets: datasets
    };

    return chartData;
  }  
}
