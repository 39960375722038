<h1>
  Tech Debt Report
  <a href="https://irissoftware.sharepoint.com/sites/EngineeringIntranet/SitePages/IRIS-Lens--Tech-Debt-Report.aspx" target="_blank">
    <p-button icon="pi pi-question"></p-button>
  </a>
</h1>

<p-toast></p-toast>

<div *ngIf="isLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>

<div *ngIf="!isLoading">

  <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
    <div style="display: flex; gap: 5px;">
      <p-multiSelect [options]="tiers"
                     [(ngModel)]="selectedTiers"
                     placeholder="Select Tiers"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onTiersChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="divisions"
                     [(ngModel)]="selectedDivisions"
                     placeholder="Select Divisions"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onDivisionsChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="suites"
                     [(ngModel)]="selectedSuites"
                     placeholder="Select Suites"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onSuitesChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="products"
                     [(ngModel)]="selectedProducts"
                     placeholder="Select Products"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onProductsChange($event)">
      </p-multiSelect>
      <label for="timeframe" class="ml-5" style="display:flex; align-items:center">
        Change vs.
      </label>
      <div class="dropdown-container">
        <p-calendar [(ngModel)]="customDate"
                    [showIcon]="true"
                    dateFormat="dd MM yy"
                    (onSelect)="onDateSelect()"
                    [minDate]="minDate"
                    [maxDate]="maxDate">
        </p-calendar>
      </div>
    </div>
  </div>

  <button pButton pRipple label="Run" icon="pi pi-play" (click)="buildReport()" title="Run" class="mb-3"></button>

</div>

<div *ngIf="isDataLoading" style="margin-top:10px;">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
  Please wait while the report is generated...
</div>

<div *ngIf="!isDataLoading && hasData" style="margin-top:10px;">

  <h2>Current Tech Debt: <span style="font-weight:bold">{{data.current | number:'1.0-0'}} days</span><span *ngIf="getDelta(data?.current ?? 0, data?.previous ?? 0) != 0" class="{{getDeltaClass(getDelta(data?.current ?? 0, data?.previous ?? 0))}}"> {{getDeltaSymbol(getDelta(data?.current ?? 0, data?.previous ?? 0))}} {{getDelta(data?.current ?? 0, data?.previous ?? 0)}}</span></h2>

  <h4>Last {{timeframe}} Days:</h4>
  <p-chart type="line" width="100%" height="300px" [data]="chartData" [options]="chartOptions"></p-chart>

  <h4 style="margin-top:20px">Product Breakdown</h4>

  <p-table [value]="breakdown"
           [paginator]="false"
           class="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:30%">
          Product / Suite
        </th>
        <th style="width:60%">
          Value (and % of total tech debt)
        </th>
        <th style="width:10%">
          Change
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>
          <a *ngIf="item.productTypeName === 'Product'" [routerLink]="'/product/' + item.objectId + '/dashboard'">
            {{ item.objectName }} ({{ item.objectCode }})
          </a>
          <a *ngIf="item.productTypeName === 'Suite'" [routerLink]="'/suite/' + item.objectId + '/dashboard'">
            {{ item.objectName }} ({{ item.objectCode }})
          </a>
        </td>
        <td>
          <p-progressBar [value]="getItemPct(item.value) | number: '1.0-0'"></p-progressBar>
          <span style="font-weight:bold">{{item.value | number: '1.0-0'}} days</span>
        </td>
        <td>
          <span class="{{getDeltaClass(getDelta(item.value ?? 0, item.previousValue ?? 0))}}"> {{getDeltaSymbol(getDelta(item.value ?? 0, item.previousValue ?? 0))}} {{getDelta(item.value ?? 0, item.previousValue ?? 0)}}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
