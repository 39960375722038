<h1>
  Vulnerability Change
</h1>

<div *ngIf="isLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>

<div *ngIf="!isLoading">
  <div>
    <h3>Filters</h3>
  </div>

  <div style="display: flex; flex-direction: column; margin-bottom: 20px;">
    <div style="display: flex; flex-wrap: wrap; gap: 5px; max-width: 100%;">
      <p-multiSelect [options]="tiers"
                     [(ngModel)]="selectedTiers"
                     placeholder="Select Tiers"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onTiersChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="divisions"
                     [(ngModel)]="selectedDivisions"
                     placeholder="Select Divisions"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onDivisionsChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="suites"
                     [(ngModel)]="selectedSuites"
                     placeholder="Select Suites"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onSuitesChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="products"
                     [(ngModel)]="selectedProducts"
                     placeholder="Select Products"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onProductsChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="severities"
                     [(ngModel)]="selectedSeverities"
                     placeholder="Select Severities"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onSeveritiesChange($event)">
      </p-multiSelect>
      <p-multiSelect [options]="tools"
                     [(ngModel)]="selectedTools"
                     placeholder="Select Tools"
                     optionLabel="Name"
                     display="chip"
                     [showToggleAll]="false"
                     (onChange)="onToolsChange($event)">
      </p-multiSelect>
      <label for="timeframe" class="ml-5" style="display:flex; align-items:center">
        Change vs.
      </label>
      <div class="dropdown-container">
        <p-calendar [(ngModel)]="customDate"
                    [showIcon]="true"
                    dateFormat="dd MM yy"
                    (onSelect)="onDateSelect()"
                    [minDate]="minDate"
                    [maxDate]="maxDate">
        </p-calendar>
      </div>
      <div class="flex flex-wrap gap-3 ml-5">
        <div class="flex align-items-center">
          <p-radioButton name="aged"
                         value="aged"
                         [(ngModel)]="aged"
                         inputId="aged1" />
          <label for="aged1" class="ml-2">
            Aged Only
          </label>
        </div>

        <div class="flex align-items-center">
          <p-radioButton name="aged"
                         value="total"
                         [(ngModel)]="aged"
                         inputId="aged2" />
          <label for="aged2" class="ml-2">
            All Vulnerabilities
          </label>
        </div>
      </div>
    </div>
  </div>

  <button pButton pRipple label="Run" icon="pi pi-play" (click)="regenerateFilters()" title="Run" class="mb-3"></button>

</div>

<div *ngIf="isDataLoading">
  <p-skeleton width="10%" height="1rem"></p-skeleton>
</div>

<div *ngIf="!isDataLoading">
  <h2>Overall Change: <span class="{{getDeltaClass()}}">{{getDelta()}} {{getDeltaSymbol()}}</span></h2>

  <p-table [value]="data"
           [paginator]="true"
           [rows]="10"
           class="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:18%" pSortableColumn="objectName">
          Product / Suite
          <p-sortIcon field="objectName"></p-sortIcon>
        </th>
        <th style="width:9%" pSortableColumn="toolName">
          Tool Name
          <p-sortIcon field="toolName"></p-sortIcon>
        </th>
        <th style="width:7%" pSortableColumn="severity">
          Sev.
          <p-sortIcon field="severity"></p-sortIcon>
        </th>
        <th style="width:37%" pSortableColumn="shortSynopsis">
          Synopsis
          <p-sortIcon field="shortSynopsis"></p-sortIcon>
        </th>
        <th style="width:7%">
          Old
        </th>
        <th style="width:8%" pSortableColumn="changeType">
          Change
          <p-sortIcon field="changeType"></p-sortIcon>
        </th>
        <th style="width:7%" pSortableColumn="instances">
          New
          <p-sortIcon field="instances"></p-sortIcon>
        </th>
        <th style="width:7%" pSortableColumn="change">
          Delta
          <p-sortIcon field="change"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>
          <a *ngIf="item.productTypeName === 'Product'" [routerLink]="'/product/' + item.objectId + '/dashboard'">
            {{ item.objectName }} ({{ item.productTypeName }})
          </a>
          <a *ngIf="item.productTypeName === 'Suite'" [routerLink]="'/suite/' + item.objectId + '/dashboard'">
            {{ item.objectName }} ({{ item.productTypeName }})
          </a>
        </td>
        <td>{{ adjustToolName(item.toolName) }}</td>
        <td [ngStyle]="{
        'background-color': getSeverityColor(item.severity),
        'color': getSeverityTextColor(item.severity)
            }"
            class="p-text-center">
          {{ item.severity }}
        </td>
        <td>
          <ng-container *ngIf="item.changeType !== 'Removed'; else noLink">
            <a [routerLink]="'/vulnerabilities/' + item.id + '/details'" [queryParams]="getVulnParams(item.toolName)">
              {{ getSynopsis(item) }}
            </a>
          </ng-container>
          <ng-template #noLink>
            {{ getSynopsis(item) }}
          </ng-template>
        </td>
        <td [ngClass]="{'p-text-secondary': calculateOldValue(item) === 0}">
          {{ calculateOldValue(item) }}
        </td>
        <td [ngClass]="getChangeTypeClass(item.changeType)" class="p-text-center">
          {{ item.changeType }}
        </td>
        <td [ngClass]="{'p-text-secondary': item.instances === 0}">
          {{ item.instances }}
        </td>
        <td [ngClass]="getChangeTypeClass(item.changeType)" class="p-text-center">
          <i [ngClass]="getChangeTypeIcon(item.changeType)" class="p-mr-2"></i> {{ item.change }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
