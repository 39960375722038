import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { VulnerabilityService } from 'src/app/layout/service/vulnerability.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ProductMetricService } from 'src/app/layout/service/product/product-metric.service';
import { Product, Suite } from 'src/app/api/product';
import { Message, MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-vuln-change',
  templateUrl: './vuln-change.component.html',
  styleUrls: ['./vuln-change.component.scss']

})
export class VulnChangeComponent implements OnInit {

  isLoading: boolean = true;
  isDataLoading: boolean = true;

  products: any[] = [];
  suites: any[] = [];
  divisions: any[] = [];
  tiers: any[] = [];
  tools: any[] = [{ Id: 0, Name: "Snyk" }, { Id: 1, Name: "Sonarqube (Enterprise)" }, { Id: 2, Name: "Tenable WAS" }, { Id: 3, Name: "Nessus" }, { Id: 4, Name: "SharePoint" }, { Id: 5, Name: "Lens" }];
  severities: any[] = [{ Id: 0, Name: "critical" }, { Id: 1, Name: "high" }, { Id: 2, Name: "medium" }, { Id: 3, Name: "low" }];
  aged: string = 'aged';

  selectedTiers: any[] = [];
  selectedDivisions: any[] = [];
  selectedSuites: any[] = [];
  selectedProducts: any[] = [];
  selectedTools: any[] = [];
  selectedSeverities: any[] = [];

  timeframe: number = 7;

  // Define the minimum and maximum dates
  minDate: Date = new Date('2024-11-18'); // November 18, 2024
  maxDate: Date = this.getYesterday();
  customDate: Date | null = null;

  delta: number = 0;
  data: any = [];

  constructor(private route: ActivatedRoute,
    private vulnerabilityService: VulnerabilityService,
    private MessageService: MessageService,
    private router: Router,
    private productService: ProductService,
    private productMetricService: ProductMetricService) { }

  ngOnInit(): void {
    forkJoin({
      suites: this.productService.getAllSuites(),
      divisions: this.productService.getAllDivisions(),
      tiers: this.productMetricService.getAllTiers(),
      products: this.productService.getAllProducts()
    }).subscribe(({ suites, divisions, tiers, products }) => {
      // Set the loaded data
      this.suites = suites.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      this.divisions = divisions.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
      this.tiers = tiers;
      this.products = products.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));

      // Parse the query parameters to set selected values
      this.route.queryParams.subscribe(params => {
        this.selectedTiers = this.getSelectedItems(params['tiers'], this.tiers);
        this.selectedDivisions = this.getSelectedItems(params['divisions'], this.divisions);
        this.selectedSuites = this.getSelectedItems(params['suites'], this.suites);
        this.selectedProducts = this.getSelectedItems(params['products'], this.products);
        this.selectedTools = this.getSelectedItems(params['tools'], this.tools);
        this.selectedSeverities = this.getSelectedItems(params['severities'], this.severities);
        this.aged = params['aged'] || 'aged';
        this.timeframe = parseInt(params['timeframe'] ?? '7');

        //Set customDate to be the date equal to midnight on the current date minus the timeframe number of days
        this.customDate = new Date();
        this.customDate.setDate(this.customDate.getDate() - this.timeframe);
        this.customDate.setHours(0, 0, 0, 0);


        const toolName = params['toolName'] ?? '';
        if (toolName !== '') {
          // Find the tool in the list of tools
          const tool = this.tools.find(item => item.Name === toolName);
          if (tool) {
            this.selectedTools = [tool];
          }
        }

        // Load the data
        this.loadData();

      });

      this.isLoading = false;
    });
  }

  // Update the URL query parameters when a selection changes
  updateQueryParams(): UrlTree {
    const urlTree = this.router.createUrlTree([], {
      queryParams: {
        tiers: this.selectedTiers.map(item => item.Id).join(','),
        divisions: this.selectedDivisions.map(item => item.Id).join(','),
        suites: this.selectedSuites.map(item => item.Id).join(','),
        products: this.selectedProducts.map(item => item.Id).join(','),
        severities: this.selectedSeverities.map(item => item.Id).join(','),        
        tools: this.selectedTools.map(item => item.Id).join(','),
        aged: this.aged,
        timeframe: this.timeframe
      },
      queryParamsHandling: 'merge'
    });

    return urlTree;
  }

  regenerateFilters() {

    this.isDataLoading = true;
    const urlTree = this.updateQueryParams();

    // Navigate to the new URL
    this.router.navigateByUrl(urlTree);

  }

  loadData() {

    const tiers = this.selectedTiers.map(item => item.Id).join(',');
    const divisions = this.selectedDivisions.map(item => item.Id).join(',');
    const suites = this.selectedSuites.map(item => item.Id).join(',');
    const products = this.selectedProducts.map(item => item.Id).join(',');
    const tools = this.selectedTools.map(item => item.Name).join(',');
    const severities = this.selectedSeverities.map(item => item.Name).join(',');


    this.vulnerabilityService.getChanges(tiers, divisions, suites, products, severities, tools, this.timeframe, this.aged).subscribe(data => {

      this.delta = data.delta;
      this.data = data.changes;

      this.isDataLoading = false;
    });

  }

  getDeltaSymbol(): string {
    return this.delta > 0 ? '▲' : '▼';
  }

  getDelta() {
    return Math.abs(this.delta);
  }

  getDeltaClass(): string {
    return this.delta > 0 ? 'text-increase' : 'text-decrease';
  }

  getSeverityColor(severity: string): string {
    const severityColors: { [key: string]: string } = {
      critical: 'var(--critical)',
      high: 'var(--high)',
      medium: 'var(--medium)',
      low: 'var(--low)'
    };
    return severityColors[severity.toLowerCase()] || 'inherit';
  }

  getSeverityTextColor(severity: string): string {
    const textColors: { [key: string]: string } = {
      critical: 'white',
      high: 'white',
      medium: 'white',
      low: 'black'
    };
    return textColors[severity.toLowerCase()] || 'black';
  }

  getChangeTypeClass(changeType: string): string {
    return {
      Removed: 'p-text-success',
      Reduced: 'p-text-success',
      Added: 'p-text-danger',
      Increased: 'p-text-danger'
    }[changeType] || '';
  }

  getChangeTypeIcon(changeType: string): string {
    return {
      Removed: 'pi pi-arrow-down',
      Decreased: 'pi pi-arrow-down',
      Added: 'pi pi-arrow-up',
      Increased: 'pi pi-arrow-up'
    }[changeType] || '';
  }

  getVulnParams(value: string): any {
    const params = {
      toolId: value
    }
    return params;
  }

  getSynopsis(value: any): string {

    const synopsis = value.shortSynopsis ?? "";

    if (synopsis.trim().length === 0) return 'Vulnerability';

    if (synopsis.length > 75) {
      return synopsis.substring(0, 75) + '...';
    }
    return synopsis;

  }

  adjustToolName(toolName: string): string {
    if (toolName === 'SharePoint') return 'Internal Pen Testing';
    if (toolName === 'Lens') return 'External Pen Testing';
    if (toolName === 'Sonarqube (Enterprise)') return 'Sonarqube';
    return toolName;
  }

  calculateOldValue(item: any): number {

    if (item.changeType === 'Removed') {
      return item.change;
    }
    if (item.changeType === 'Added') {
      return 0;
    }
    if (item.changeType === 'Increased') {
      return item.instances - item.change;
    }
    if (item.changeType === 'Reduced') {
      return item.instances + item.change;
    }
    return 0;
  }

  private getSelectedItems(paramValues: string, allItems: any[]): any[] {
    if (!paramValues) {
      return [];
    }
    const ids = paramValues.split(',').map(Id => Id.trim());
    return allItems.filter(item => ids.includes(item.Id.toString()));
  }

  onTiersChange(event: any) {
    this.selectedTiers = event.value;
  }

  onDivisionsChange(event: any) {
    this.selectedDivisions = event.value;
  }

  onSuitesChange(event: any) {
    this.selectedSuites = event.value;
  }

  onProductsChange(event: any) {
    this.selectedProducts = event.value;
  }

  onToolsChange(event: any) {
    this.selectedTools = event.value;
  }

  onSeveritiesChange(event: any) {
    this.selectedSeverities = event.value;
  }


  onDateSelect(): void {
    if (this.customDate) {
      const today = new Date();
      const selectedDate = new Date(this.customDate);
      const timeDiff = today.getTime() - selectedDate.getTime();
      this.timeframe = Math.floor(timeDiff / (1000 * 3600 * 24));
    }
  }

  getYesterday(): Date {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1); // Subtract one day from today
    return yesterday;
  }

}
