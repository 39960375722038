{
  "name": "iris-lens-client",
  "version": "1.12.5",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "release": "standard-version",
    "release:major": "standard-version --release-as major",
    "release:minor": "standard-version --release-as minor",
    "release:patch": "standard-version --release-as patch"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.2",
    "@angular/cdk": "^18.1.3",
    "@angular/common": "^18.1.2",
    "@angular/compiler": "^18.1.2",
    "@angular/core": "^18.1.2",
    "@angular/forms": "^18.1.2",
    "@angular/platform-browser": "^18.1.2",
    "@angular/platform-browser-dynamic": "^18.1.2",
    "@angular/router": "^18.1.2",
    "@azure/msal-angular": "^3.0.11",
    "@azure/msal-browser": "^3.7.1",
    "chart.js": "^4.4.1",
    "chartjs-adapter-date-fns": "^3.0.0",
    "date-fns": "^4.1.0",
    "file-saver": "^2.0.5",
    "material-icons": "^1.13.12",
    "ngx-cookie-service": "^17.1.0",
    "ngx-gauge": "^9.0.0",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.2",
    "quill": "^1.3.7",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.2/xlsx-0.20.2.tgz",
    "zone.js": "^0.14.8"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.2",
    "@angular/cli": "^18.1.2",
    "@angular/compiler-cli": "^18.1.2",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~4.3.0",
    "@types/node": "^20.11.8",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "standard-version": "^9.5.0",
    "typescript": "5.5.4",
    "webpack": "^5.89.0"
  }
}
