import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/api/product';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ToolExternalService } from 'src/app/layout/service/tool-external.service';
import { AssetThinDto } from 'src/app/api/asset';
import { Table } from 'primeng/table';
import { Message, MessageService } from 'primeng/api';
import FileSaver from 'file-saver';

@Component({
  selector: 'app-product-assets',
  templateUrl: './product-assets.component.html'
})
export class ProductAssetsComponent implements OnInit {
  productId: string = '';
  isLoading = true;
  assets: AssetThinDto[] = [];
  isAssetsLoading: boolean = true;
  @ViewChild('dt1') table!: Table;
  cols: any[] = [];
  recordCount: number = 0;
  messages: Message[] = [];

  product: Product = {};

  displayAssets: any[] = [];

  constructor(private route: ActivatedRoute,
    private productService: ProductService,
    private toolExternalService: ToolExternalService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.productId = params['id'];

      this.cols = [
        { field: 'Name', header: 'Name', minWidth: 'min-width: 6rem', type: 'text' },
        { field: 'Type', header: 'Type', minWidth: 'min-width: 6rem', type: 'text' },
        { field: 'OperatingSystem', header: 'OperatingSystem', minWidth: 'min-width: 6rem', type: 'text' },
        { field: 'FQDN', header: 'FQDN', minWidth: 'min-width: 6rem', type: 'text' },
      ]

      this.toolExternalService.getAssets(parseInt(this.productId)).subscribe(
        (data: any) => {
          this.recordCount = data.records;
          if (data.productType == "Suite") {
            this.messages = [{ severity: 'info', summary: 'Information', detail: 'No assets available at product level. Shwing assets of the parent suite: ' + data.objectName }];
          }
          const assets = data.data;

          if (Array.isArray(assets)) {
            assets.forEach((element: any) => {
              this.displayAssets.push({
                id: element.id,
                Name: this.getAssetName(element),
                Type: this.getAssetType(element),
                FQDN: this.getFQDN(element),
                OperatingSystem: this.getOperatingSystem(element),
              });
            });
          }
          this.table.filterGlobal('', 'contains');
          this.isAssetsLoading = false; 
        },
        (error) => {          
          console.error('Error fetching assets:', error);
          this.isAssetsLoading = false; 

        }
      );
    });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clear(table: Table) {
    table.clear();
    this.table.filterGlobal('', 'contains');
  }

  getAssetName(asset: any): string {
    const assetName = asset?.hostnames?.[0] ?? asset.id;
    return assetName;
  }

  getAssetType(asset: any): string {

    const assetType = asset?.systemTypes?.[0] ?? "";
    switch (assetType) {
      case "aws-ec2-instance":
        return "AWS";
      case "azure-instance":
        return "Azure";
      default:
        return "Rackspace";
    }
  }

  getFQDN(asset: any): string {
    const fqdn = asset?.fqdns?.[0] ?? "";
    return fqdn;
  }

  getOperatingSystem(asset: any): string {

    const os = asset?.operatingSystems?.[0] ?? "";
    if(os.includes("Windows")) {
      return "Windows";
    };
    if(os.includes("Linux")) {
      return "Linux";
    };
    return os;
  }

  tagify(option: string | undefined): string {
    if (!option) {
      return '';
    }
    let tagValue = option.trim();
    tagValue = tagValue.replace(/\s+/g, '_');
    tagValue = tagValue.replace(/[^a-zA-Z0-9_]/g, '');
    return tagValue;
  }

    exportExcel() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.displayAssets);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "assets");
      });
    }
  
      saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }

}
