import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { EmailRequestDto, EmailRequestNewDto } from 'src/app/api/email-request';
import { User } from 'src/app/api/product';
import { OneDayTestContact, ProductOneDayPentest, ProductOneDayPentestPutDto, ProductOneDayPentestRequestDto } from 'src/app/api/product-onedaypentest';
import { PTTContactDto, ProductPTTDto, ProductPTTPutDto } from 'src/app/api/product-ptt';
import { CheckRoleService } from 'src/app/layout/service/check-role.service';
import { GraphExplorerService } from 'src/app/layout/service/graphexplorer/graph-explorer.service';
import { LogService } from 'src/app/layout/service/log.service';
import { EmailService } from 'src/app/layout/service/product/email.service';
import { ProductOneDayPentestService } from 'src/app/layout/service/product/product-onedaypentest.service';
import { ProductPTTService } from 'src/app/layout/service/product/product-ptt.service';
import { ProductSDLCService } from 'src/app/layout/service/product/product-sdlc.service';
import { ProductService } from 'src/app/layout/service/product/products.service';
import { ToEmailService } from 'src/app/layout/service/product/to-email.service';
import { ProductSelectionService } from 'src/app/product.selection.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-oneday-pentest',
  templateUrl: './oneday-pentest.component.html',
  styleUrls: ['./oneday-pentest.component.scss']
})
export class OneDayPentestComponent implements OnInit {
  productId: string = '';
  productReference: string | undefined;
  isLensAdmin: boolean = false;
  
  productOneDayPentests: ProductOneDayPentest[] = [];
  editedProductOneDayPentest: ProductOneDayPentestPutDto = {};
  productOneDayPentest: ProductOneDayPentestRequestDto = {};

  hostingEnvironments: any[] = [];
  sdlcEnvironments: any[] = [];
  testTriggers: any[] = [];
  testRemediationStatuses: any[] = [];

  testTargetTypes: any[] = []; // multiselect
  selectedTestTargetTypes: any[] = []; // multiselect
  
  pttComplexity: any[] = [];
  pttPriority: any[] = [];
  pttProgress: any[] = [];
  pttSeverity: any[] = [];
  
  originalSelectedOption: ProductOneDayPentest = {};
  selectedOneDayPentestOptionTargetTypes: any[] = [];

  hasSelectedOption: boolean = false;
  selectedOneDayPentestId: number | undefined;
  selectedOneDayPentestOption: ProductOneDayPentestPutDto = {};

  submitted: boolean = false;
  oneDayPentestDialog: boolean = false;

  messages: Message[] = [];
  messages2: Message[] = [];

  productOneDayPentestById: ProductOneDayPentest = {};
  productOneDayPentestByIdTargetType: any[] = [];


  userDialog: boolean = false;
  users: User[] = [];
  oneDayTestContacts: OneDayTestContact[] = [];

  selectedUser: User | null = null;

  sdlcNewOneDayContacts: User[] = [];
  userDialogAction: string = '';

  pentestPTTDialog: boolean = false;

  pttNewContacts: User[] = [];
  pttContacts: PTTContactDto[] = [];
  pttContactDialog: boolean = false;

  pentestPTT: ProductPTTDto[] = [];
  
  hasFindings: boolean = false;

  visibleProductOneDayPentests: ProductOneDayPentest[] = []; // Subset of items to display based on pagination
  currentPage: number = 1; // Current page
  pageSize: number = 5; // Number of items per page

  productName: string | undefined;

  columns: any[] = [];

  constructor(private route: ActivatedRoute, private productOneDayPentestService: ProductOneDayPentestService, private messageService: MessageService,
    private productSelectionService: ProductSelectionService, private productService: ProductService, private productSDLCService: ProductSDLCService,
    private changeDetectorRef: ChangeDetectorRef, private graphExplorerService: GraphExplorerService, private confirmationService: ConfirmationService, 
    private productPTTService: ProductPTTService, private router: Router, private emailService: EmailService, private toEmailService: ToEmailService,
    private logService: LogService, private checkRoleService: CheckRoleService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.productId = params['id'];

      this.productSelectionService.getSelectedProduct().subscribe(product => {
        if (product) {
          this.productName = product.Name;
          this.productReference = product.ProductRef;
          // console.log(this.productName);
        } else {
          // console.log('No product selected');
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No product selected' });
        }
      });

      this.route.queryParams.subscribe(params => {
        const selectedOneDayPentestId = this.route.snapshot.queryParams['id'];
        if (selectedOneDayPentestId) {
            // Call your method to select the pentest
            this.showOneDayTestDetails(selectedOneDayPentestId);
        }
      });

      this.fetchOneDayPentestData();

      this.isLensAdmin = this.checkRoleService.hasRole(['Lens.Admin']);
    });

    this.productOneDayPentestService.getAllHostingEnvironments().subscribe(hostingEnvironment => {
      this.hostingEnvironments = hostingEnvironment;
    });

    this.productSDLCService.getAllSDLCEnvironments().subscribe(environment => {
      this.sdlcEnvironments = environment;
    });

    this.productOneDayPentestService.getAllTestTriggers().subscribe(testTrigger => {
      this.testTriggers = testTrigger;
    });

    this.productOneDayPentestService.getAllTestRemediationStatuses().subscribe(testRemediationStatus => {
      this.testRemediationStatuses = testRemediationStatus;
    });

    this.productOneDayPentestService.getAllTestTargetTypes().subscribe(testTargetType => {
      this.testTargetTypes = testTargetType;
    });

    this.productPTTService.getAllPTTComplexity().subscribe(pttComplexity => {
      this.pttComplexity = pttComplexity;
    });

    this.productPTTService.getAllPTTPriority().subscribe(pttPriority => {
      this.pttPriority = pttPriority;
    });

    this.productPTTService.getAllPTTProgress().subscribe(pttProgress => {
      this.pttProgress = pttProgress;
    });

    this.productPTTService.getAllPTTSeverity().subscribe(pttSeverity => {
      this.pttSeverity = pttSeverity;
    });

    this.getAllUsers();

    this.messages = [{ severity: 'info', summary: 'Information', detail: 'Select Pentest from the menu to view' }];

    this.columns = [
      { field: 'OneDayTestBatchNumberId', header: '1d Pentest Id', minWidth: '12rem', type: 'text' },
      { field: 'SeverityName', header: 'Severity', minWidth: '12rem', type: 'text' },
      { field: 'CVSS', header: 'CVSS', minWidth: '12rem', type: 'text' },
      { field: 'PTTProgressName', header: 'Progress', minWidth: '12rem', type: 'text' },
      { field: 'IssueTitle', header: 'Issue', minWidth: '12rem', type: 'text' },
      { field: 'Issue', header: 'Issue', minWidth: '12rem', type: 'text' },
      { field: 'IssueLocation', header: 'Issue', minWidth: '12rem', type: 'text' },
      { field: 'Remediation', header: 'Remediation', minWidth: '12rem', type: 'text' },
      { field: 'PriorityName', header: 'Priority', minWidth: '12rem', type: 'text' },
      { field: 'FixDate', header: 'Fix Date (ETA)', minWidth: '12rem', type: 'text' },
      { field: 'FixDateNum', header: 'Fix Date (Num)', minWidth: '12rem', type: 'text' },
      { field: 'ComplexityName', header: 'Complexity', minWidth: '12rem', type: 'text' },
      { field: 'AssignedTo', header: 'Assigned to', minWidth: '12rem', type: 'text' },
      { field: 'Comment', header: 'Comment', minWidth: '12rem', type: 'text' },
      { field: 'TestDate', header: 'Test Date', minWidth: '12rem', type: 'text' },
      { field: 'NextAction', header: 'Next Action', minWidth: '12rem', type: 'text' },
      { field: 'Vector', header: 'Vector', minWidth: '12rem', type: 'text' }
    ];
    
  }

  getAllUsers() {
    this.graphExplorerService.getAllADUsers().subscribe((users) => {
      this.users = users;
    });
  }

  searchUsers(event?: any) {
    if (event.filter) {
      this.graphExplorerService.searchADUsers(event.filter).subscribe((users) => {
        this.users = users;
      });
    } else {
      this.getAllUsers();
    }
  }

  getHostingEnvironmentNameById(id: number): string {
    const hostingEnvironment = this.hostingEnvironments.find(x => x.Id === id);
    return hostingEnvironment?.Name || '';
  }

  getSDLCEnvironmentNameById(id: number): string {
    const sdlcEnvironment = this.sdlcEnvironments.find(x => x.Id === id);
    return sdlcEnvironment?.Name || '';
  }

  getTestTriggerNameById(id: number): string {
    const testTrigger = this.testTriggers.find(x => x.Id === id);
    return testTrigger?.Name || '';
  }

  getTestRemediationStatusNameById(id: number): string {
    const testRemediationStatus = this.testRemediationStatuses.find(x => x.Id === id);
    return testRemediationStatus?.Name || '';
  }

  getTestTargetTypeNameById(id: number): string {
    const testTargetType = this.testTargetTypes.find(x => x.Id === id);
    return testTargetType?.Name || '';
  }

  oneDayPentestBatchNumber: string = '';
  
  showOneDayTestDetails(selectedOneDayPentestId: number) {
    
    this.productOneDayPentestService.getProductOneDayPentestById(selectedOneDayPentestId).subscribe((productOneDayPentest) => {
      const selectedOneDayPentest = productOneDayPentest.value[0];
      this.selectedOneDayPentestOption = selectedOneDayPentest;
      this.selectedOneDayPentestId = selectedOneDayPentest.Id;
      this.oneDayPentestBatchNumber = selectedOneDayPentest.OneDayTestBatchNumberId;

      this.hasSelectedOption = true;
      this.editedProductOneDayPentest = { ...this.selectedOneDayPentestOption};
      this.fetchOneDayPentestDataById();

      this.refreshPTT()

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { id: selectedOneDayPentestId },
        queryParamsHandling: 'merge'
      });
    })
  }

  fetchOneDayPentestData() {
    this.productOneDayPentestService.getProductOneDayPentests(this.productId).subscribe((productOneDayPentests) => {
      this.productOneDayPentests = productOneDayPentests.value;
      this.productOneDayPentests.sort((a, b) => (b.Id || 0) - (a.Id || 0));
      this.selectedTestTargetTypes = [];

      this.updateVisibleItems();

      if (this.productOneDayPentests.length > 0) {
        this.showOneDayTestDetails(this.productOneDayPentests[0].Id ?? 0);
      };      

    });


    if (this.selectedOneDayPentestId !== undefined) {
      this.fetchOneDayPentestDataById();
      this.fetchPTTContacts();
      // this.showOneDayTestDetails(this.productOneDayPentests.find(x => x.Id === this.selectedOneDayPentestId) || {});
    }
  }

  fetchOneDayPentestDataById() {
    if(this.selectedOneDayPentestId !== undefined)
    {
      this.productOneDayPentestService.getProductOneDayPentestById(this.selectedOneDayPentestId).subscribe((productOneDayPentest) => {
        this.productOneDayPentestById = productOneDayPentest.value[0];
        this.originalSelectedOption = this.productOneDayPentestById;

        this.selectedOneDayPentestOptionTargetTypes = this.productOneDayPentestById.OneDayTestTargetTypes?.map(type => type.TestTargetTypeId) || [];
      });
      this.fetchOneDayTestContacts(); //new
    }
  }
  
//new stuff
  fetchOneDayTestContacts() {
    if (this.selectedOneDayPentestId) {
      this.productOneDayPentestService.getOneDayTestContacts(this.selectedOneDayPentestId).subscribe((oneDayTestContacts) => {
        this.oneDayTestContacts = oneDayTestContacts;
        // I need this.sdlcPrimaryContacts to only contain the 
      });
    }
  }

  openNew() {
    this.editedProductOneDayPentest = {};
    this.submitted = false;
    this.oneDayPentestDialog = true; 
  }

  hideDialog() {
    this.oneDayPentestDialog = false;
    this.submitted = false;

    this.productOneDayPentest = {};
    this.selectedTestTargetTypes = [];
    this.sdlcNewOneDayContacts = [];
  }

  openUserDialog(action: string) {
    this.userDialogAction = action;
    this.userDialog = true;
  }

  hideUserDialog() {
    this.userDialog = false;
    this.selectedUser = null;
  }

  saveNewOneDayPentest() {
    this.submitted = true;

    if((this.productOneDayPentest.HostingEnvironmentId && this.productOneDayPentest.SDLCEnvironmentId) || this.isLensAdmin) {

      this.productOneDayPentest.ProductId = this.productId;
      this.productOneDayPentestService.createProductOneDayPentest(this.productOneDayPentest).subscribe((productNewOneDayPentest: any) => {
        const newEntityId = productNewOneDayPentest.Id;

        if (newEntityId) {
          this.selectedTestTargetTypes.forEach(type => {
            this.productOneDayPentestService.addOneDayTestTargetType({
              ProductOneDayPentestId: newEntityId,
              TestTargetTypeId: type,
            }).subscribe(() => {
            });
          });
        }

        this.sdlcNewOneDayContacts.forEach((contact) => {
          const productUser = {
            UserGraphId: contact.id,
            Name: contact.displayName,
            Mail: contact.displayName,
            ProductOneDayPentestId: newEntityId
          };

          if (productUser.UserGraphId && productUser.Name && productUser.Mail && newEntityId) {
            this.productOneDayPentestService.createOneDayTestContact(productUser).subscribe((response) => {
              this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
              this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/onedaypentest/' + newEntityId + "/users", productUser);
            });
          }
        });

        const toEmails = this.toEmailService.getToEmails();

        // Define the list of CC recipients
        const ccEmails = this.sdlcNewOneDayContacts.map(contact => contact.mail);

        this.sendEmail(
          toEmails,
          ccEmails,
          `1d Pentest Created - ${this.productName} (${this.productId})`,
          `
            <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
              <h2 style="color: #0056b3;">1d Pentest Created Notification</h2>
              <p>A new 1d Pentest has been created for:</p>
              <table style="border-collapse: collapse; width: 100%; margin-bottom: 20px;">
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Id:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productId}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Name:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productName}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Reference:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productReference}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">1d Pentest Id:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${newEntityId}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Link to 1d Pentest Page:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">
                    <a href="${environment.redirectUri}/product/${this.productId}/sdlc/onedaypentest?id=${newEntityId}" style="color: #0056b3; text-decoration: none;">Go to Page</a>
                  </td>
                </tr>
              </table>
              <div style="margin-top: 20px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd;">
                <p style="font-size: 14px; color: #555;">This is an automated message. If you have any questions, please reach out to the relevant contacts.</p>
              </div>
            </div>
          `
        );        

        this.logService.sendInfoMessage('CREATE', 'product/' + this.productId + '/sdlc/onedaypentest/', this.productOneDayPentest);

        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'One Day Pentest Created', life: 3000 });
        this.oneDayPentestDialog = false;
        this.productOneDayPentest = {};
        this.sdlcNewOneDayContacts = [];
        this.submitted = false;
        
        this.fetchOneDayPentestData();
      });
    }
    // this.submitted = false;
  }

  addNewOneDayContact() {
    if (this.selectedUser != null) {
      const userExists = this.sdlcNewOneDayContacts.some(contact => contact.id === this.selectedUser!.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added as new contact', life: 3000 });
      } else {
        this.sdlcNewOneDayContacts.push(this.selectedUser);
        this.hideUserDialog();
      }
    }
    this.selectedUser = null;
  }

  removeNewOneDayContact(contact: User) {
    const index = this.sdlcNewOneDayContacts.indexOf(contact);
    if (index !== -1) {
        this.sdlcNewOneDayContacts.splice(index, 1);
    }
  }

  deleteOneDayTest(oneDayTest: ProductOneDayPentest) {
    this.confirmationService.confirm({
        message: `Are you sure you want to delete this 1d Pentest? (Id: ${oneDayTest.Id})`,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger p-button-text",
        rejectButtonStyleClass:"p-button-text p-button-text",
        acceptIcon:"none",
        rejectIcon:"none",
        accept: () => {
            // User confirmed, perform deletion
            if (oneDayTest.Id) {
                this.productOneDayPentestService.deleteProductOneDayPentest(oneDayTest.Id).subscribe((response) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: '1d Pentest deleted', life: 3000 });
                    this.fetchOneDayPentestData();
                    this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/onedaypentest/', null, oneDayTest);
                });
            }
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

  deletePTTFinding(id: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this Finding?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger p-button-text",
      rejectButtonStyleClass:"p-button-text p-button-text",
      acceptIcon:"none",
      rejectIcon:"none",
      accept: () => {
        // User confirmed, perform deletion
        if (id) {
          this.productPTTService.deletePTTFinding(id).subscribe((response) => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Finding deleted', life: 3000 });
            this.refreshPTT();
            this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/onedaypentest/finding/', null, id);
          });
        }
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

  onSubmit() {
    if (this.submitted) {
      return;
    }


    this.submitted = true;

    if (this.selectedOneDayPentestOption && this.selectedOneDayPentestId != undefined) {

      this.productOneDayPentestService.updateProductOneDayPentest(this.selectedOneDayPentestId, this.editedProductOneDayPentest).subscribe((productOneDayPentest) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'One Day Pentest Updated', life: 3000 });
        this.submitted = false;
        this.fetchOneDayPentestData();

        const toEmails = this.toEmailService.getToEmails();

        // Define the list of CC recipients
        const ccEmails = this.oneDayTestContacts.map(contact => contact.Mail);

        this.sendEmail(
          toEmails,
          ccEmails,
          `1d Pentest Updated - ${this.productName} (${this.productId})`,
          `
            <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
              <h2 style="color: #0056b3;">1d Pentest Update Notification</h2>
              <p>Details for the 1d Pentest have been updated for:</p>
              <table style="border-collapse: collapse; width: 100%; margin-bottom: 20px;">
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Id:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productId}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Name:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productName}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Product Reference:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.productReference}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">1d Pentest Entry Id:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${this.selectedOneDayPentestOption.Id}</td>
                </tr>
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Link to 1d Pentest Page:</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">
                    <a href="${environment.redirectUri}/product/${this.productId}/sdlc/onedaypentest?id=${this.selectedOneDayPentestOption.Id}" style="color: #0056b3; text-decoration: none;">Go to Page</a>
                  </td>
                </tr>
              </table>
              <div style="margin-top: 20px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd;">
                <p style="font-size: 14px; color: #555;">This is an automated message. If you have any questions, please reach out to the relevant contacts.</p>
              </div>
            </div>
          `
        );               
        
        this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/sdlc/onedaypentest/', this.editedProductOneDayPentest, this.selectedOneDayPentestOption);

      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'One Day Pentest Update Failed', life: 3000 });
        this.submitted = false;
    });

    const originalTestTargetTypes = this.originalSelectedOption.OneDayTestTargetTypes?.map(type => ({ Id: type.Id, TestTargetTypeId: type.TestTargetTypeId })) || [];

    const addedTestTargetTypes = this.selectedOneDayPentestOptionTargetTypes.filter(type => !originalTestTargetTypes.some(originalType => originalType.TestTargetTypeId === type));
    
    const removedTestTargetTypes = originalTestTargetTypes.filter(type => !this.selectedOneDayPentestOptionTargetTypes.includes(type.TestTargetTypeId)).map(type => type.Id);


    if (addedTestTargetTypes.length > 0) {
      addedTestTargetTypes.forEach(type => {
        this.productOneDayPentestService.addOneDayTestTargetType({
          ProductOneDayPentestId: this.selectedOneDayPentestId,
          TestTargetTypeId: type,
        }).subscribe(() => {
        });
      })
    }

    if (removedTestTargetTypes.length > 0) {
      removedTestTargetTypes.forEach(id => {
        if (id != undefined) {
          this.productOneDayPentestService.removeOneDayTestTargetType(id).subscribe(() => {
          });
        }
      });
    }

    this.submitted = false;
    this.fetchOneDayPentestData();
    this.changeDetectorRef.detectChanges();

  } else {
    this.messageService.add({ severity: 'error', summary: 'Select a practice from the left menu', });
    this.submitted = false;
  }
  
  }

  addOneDayTestContact() {
    if (this.selectedUser && this.productId) {
      const userExists = this.oneDayTestContacts.some(contact => contact.UserGraphId === this.selectedUser!.id);
      if (userExists) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'User already added', life: 3000 });
      } else {
        const productUser = {
          UserGraphId: this.selectedUser.id,
          Name: this.selectedUser.displayName,
          Mail: this.selectedUser.mail,
          ProductOneDayPentestId: this.selectedOneDayPentestId
        };
  
        if (productUser.UserGraphId && productUser.Name && productUser.Mail && productUser.ProductOneDayPentestId && this.selectedOneDayPentestId) {
          this.productOneDayPentestService.createOneDayTestContact(productUser).subscribe((response) => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${productUser.Name} added`, life: 3000 });
  
            this.fetchOneDayTestContacts();
            this.hideUserDialog();
          });
        }
      }
    }
  }

  removeUser(contact: OneDayTestContact) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to remove this user?',
        accept: () => {
            // User confirmed, perform deletion
            const index = this.oneDayTestContacts.indexOf(contact);
            if (index !== -1 && contact.Id) {
                this.oneDayTestContacts.splice(index, 1);
                // Perform the actual deletion from your backend here if necessary
                this.productOneDayPentestService.deleteOneDayTestContact(contact.Id).subscribe((response) => {
                  this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
                  this.logService.sendInfoMessage('DELETE', 'product/' + this.productId + '/sdlc/onedaypentest/' + this.selectedOneDayPentestId + "/users/" + contact.Id, null, contact);
                });
            }
        }
    });
 }

  onPageChange(event: any) {
    this.currentPage = event.page + 1;
    this.updateVisibleItems();
  }

  // Method to update the subset of items to display
  updateVisibleItems() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.visibleProductOneDayPentests = this.productOneDayPentests.slice(startIndex, endIndex);
  }

  displayFullTextDialog: boolean = false;
  selectedText: string = '';
  selectedTitle: string = '';

  showPTT() {
    // this.refreshPTT();
    this.pentestPTTDialog = true;
  }

  refreshPTT() {
    this.pentestPTT = [];
    if (this.selectedOneDayPentestId && this.oneDayPentestBatchNumber) {
      this.productPTTService.getProductPTTByOneDayPentestId(this.oneDayPentestBatchNumber, this.productId).subscribe((productPTT) => {
        this.pentestPTT = productPTT.value;
        if (this.pentestPTT.length > 0) {
          this.hasFindings = true;
        } else {
          this.hasFindings = false;
        }
      })
    }
  }

  viewFullText(text: string, title: string) {
    this.selectedText = text;
    this.selectedTitle = title;
    this.displayFullTextDialog = true;
  }

  onPTTEditSave(pentestPTTDto: ProductPTTPutDto) {
    if (pentestPTTDto.Id != undefined) {
      this.productPTTService.updateProductPTT(pentestPTTDto.Id, pentestPTTDto).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'PTT Updated', life: 3000 });
      });
    }
  }

  editPTTDialog: boolean = false;
  editPentestPTT: ProductPTTPutDto = {};

  editPTT(pentestPTT: ProductPTTPutDto) {
    this.editPentestPTT = {...pentestPTT};
    this.editPentestPTT.FixDate = this.editPentestPTT.FixDate ? new Date(this.editPentestPTT.FixDate) : undefined;
    this.editPentestPTT.TestDate = this.editPentestPTT.TestDate ? new Date(this.editPentestPTT.TestDate) : undefined;

    this.fetchPTTContacts();

    this.editPTTDialog = true;
  }

  hideEditPTTDialog() {
    this.editPTTDialog = false;
  }

  updatePTT() {
    if (this.editPentestPTT.Id != undefined) {
      this.productPTTService.updateProductPTT(this.editPentestPTT.Id, this.editPentestPTT).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'PTT Updated', life: 3000 });
        this.editPTTDialog = false;
        this.editPentestPTT = {};

        this.fetchOneDayPentestDataById();
        this.refreshPTT();
        this.logService.sendInfoMessage('UPDATE', 'product/' + this.productId + '/sdlc/onedaypentest/finding/', this.editPentestPTT);
      });
    }
  }

  getSeverityNameById(id: number): string {
    const severity = this.pttSeverity.find(x => x.Id === id);
    return severity?.Name || '';
  }

  getComplexityNameById(id: number): string {
    const complexity = this.pttComplexity.find(x => x.Id === id);
    return complexity?.Name || '';
  }

  getPriorityNameById(id: number): string {
    const priority = this.pttPriority.find(x => x.Id === id);
    return priority?.Name || '';
  }

  getProgressNameById(id: number): string {
    const progress = this.pttProgress.find(x => x.Id === id);
    return progress?.Name || '';
  }

  addPTTContact() {
    const productUser = {
      UserGraphId: this.selectedUser?.id,
      Name: this.selectedUser?.displayName,
      Mail: this.selectedUser?.mail,
      ProductPTTId: this.editPentestPTT.Id // needs to be PTT Id
    };

    if (this.selectedUser && this.productId && productUser.UserGraphId && productUser.Name && productUser.ProductPTTId) {
      this.productPTTService.createPTTContact(productUser).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Added', life: 3000 });
        
        this.fetchPTTContacts();
      });
    }

    this.selectedUser = null;
  }


  fetchPTTContacts() {
    if (this.editPentestPTT.Id) {
      this.productPTTService.getPTTContacts(this.editPentestPTT.Id).subscribe((pttContacts) => {
        this.pttContacts = pttContacts;
      });
    }
  }

  removePTTContact(contact: PTTContactDto) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to remove this user?',
        accept: () => {
            // User confirmed, perform deletion
            const index = this.pttContacts.indexOf(contact);
            if (index !== -1 && contact.Id) {
              this.pttContacts.splice(index, 1);
              // Perform the actual deletion from your backend here if necessary
              this.productPTTService.deletePTTContact(contact.Id).subscribe((response) => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User removed', life: 3000 });
              });
            }
          }
    });
  }

  openPTTContactDialog() {
    this.pttContactDialog = true;
  }

  hidePTTContactDialog() {
    this.pttContactDialog = false;
  }

      // Emails

      sendEmail(toEmails: string[], ccEmails: string[], subject: string, htmlContent: string) {
        const emailRequest: EmailRequestNewDto = {
            toEmails: toEmails,
            ccEmails: ccEmails,
            subject: subject,
            htmlContent: htmlContent,
            plainTextContent: '' // Assuming no plain text content in this case
        };
    
        this.emailService.sendEmailNew(emailRequest).subscribe(response => {
            console.log('Email sent successfully', response);
        }, error => {
            console.error('Error sending email', error);
        });
      }
}
